import React from "react"
import Img from "gatsby-image"

export function ImageSet({ children }) {
  if (children.length !== 2) {
    throw new Error("Expected two images in an image set.")
  }
  return <div className="image-set">{children}</div>
}

export function Image({ aspectRatio, caption, image, type }) {
  let sizeClass = ""
  switch (type) {
    case "wide": {
      sizeClass = "kg-width-wide"
      break
    }
    case "full": {
      sizeClass = "kg-width-full"
      break
    }
  }

  return (
    <figure className={`kg-card kg-image-card ${sizeClass}`}>
      <Img
        fluid={{
          ...image.childImageSharp.fluid,
          aspectRatio: aspectRatio || image.childImageSharp.fluid.aspectRatio,
        }}
        className="kg-image"
      />
      {caption && <figcaption>{caption}</figcaption>}
    </figure>
  )
}
