import React from "react"
import { graphql, StaticQuery } from "gatsby"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Image, ImageSet } from "../components/image"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const Page = ({ data }) => {
  const lightboxOptions = {
    settings: {},
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
    caption: {
      showCaption: false,
    },
  }
  return (
    <Layout title="">
      <SEO
        title="Blackberry Woods Camping 2020"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />
      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <SimpleReactLightbox>
            <SRLWrapper options={lightboxOptions}>
              <h2>Blackberry Woods Camping</h2>
              <Image image={data.P1670033} type="wide" />
              <Image image={data.P1670027} type="wide" />
              <Image image={data.P1670062} aspectRatio={2 / 3} />
              <ImageSet>
                <Image image={data.P1670143} />
                <Image image={data.P1670173} />
              </ImageSet>
              <ImageSet>
                <Image image={data.P1670212} />
                <Image image={data.P1670216} />
              </ImageSet>
              <Image image={data.P1670220} type="wide" />
              <Image image={data.P1670239} type="wide" />
              <Image
                image={data.P1670254}
                aspectRatio={2 / 3}
                caption="EXIT game!"
              />
              <ImageSet>
                <Image image={data.P1670322} />
                <Image image={data.P1670331} />
              </ImageSet>
              <Image image={data.P1670334} type="wide" />
              <Image
                image={data.P1670345}
                type="wide"
                caption="On the road to Hassocks"
              />
              <Image image={data.P1670359} type="wide" />
              <ImageSet>
                <Image image={data.P1670378} caption="Happy Halloween!" />
                <Image image={data.P1670391} caption="Can you spot the rain?" />
              </ImageSet>
              <Image image={data.P1670403} type="wide" />
              <Image image={data.P1670407} type="wide" />
              <Image image={data.P1670412} type="wide" />
              <ImageSet>
                <Image image={data.P1670429} />
                <Image image={data.P1670458} />
              </ImageSet>
              <Image image={data.P1670466} type="wide" />
              <Image image={data.P1670472} type="full" />
              <Image image={data.P1670509} type="wide" />
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
      </article>
    </Layout>
  )
}

const query = graphql`
  fragment MediumImage on File {
    childImageSharp {
      fluid(maxWidth: 1500, quality: 30) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment FullImage on File {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  query {
    site {
      siteMetadata {
        title
      }
    }

    P1670027: file(name: { eq: "P1670027" }) {
      ...MediumImage
    }
    P1670033: file(name: { eq: "P1670033" }) {
      ...MediumImage
    }
    P1670062: file(name: { eq: "P1670062" }) {
      ...MediumImage
    }
    P1670143: file(name: { eq: "P1670143" }) {
      ...MediumImage
    }
    P1670173: file(name: { eq: "P1670173" }) {
      ...MediumImage
    }
    P1590612: file(name: { eq: "P1590612" }) {
      ...MediumImage
    }
    P1670160: file(name: { eq: "P1670160" }) {
      ...MediumImage
    }
    P1590662: file(name: { eq: "P1590662" }) {
      ...MediumImage
    }
    P1670212: file(name: { eq: "P1670212" }) {
      ...MediumImage
    }
    P1670216: file(name: { eq: "P1670216" }) {
      ...MediumImage
    }
    P1590600: file(name: { eq: "P1590600" }) {
      ...MediumImage
    }
    P1670220: file(name: { eq: "P1670220" }) {
      ...MediumImage
    }
    P1670239: file(name: { eq: "P1670239" }) {
      ...MediumImage
    }
    P1670254: file(name: { eq: "P1670254" }) {
      ...MediumImage
    }
    P1670322: file(name: { eq: "P1670322" }) {
      ...MediumImage
    }
    P1670331: file(name: { eq: "P1670331" }) {
      ...MediumImage
    }
    P1670334: file(name: { eq: "P1670334" }) {
      ...MediumImage
    }
    P1670345: file(name: { eq: "P1670345" }) {
      ...MediumImage
    }
    P1670359: file(name: { eq: "P1670359" }) {
      ...MediumImage
    }
    P1670362: file(name: { eq: "P1670362" }) {
      ...MediumImage
    }
    P1670378: file(name: { eq: "P1670378" }) {
      ...MediumImage
    }
    P1670391: file(name: { eq: "P1670391" }) {
      ...MediumImage
    }
    P1670398: file(name: { eq: "P1670398" }) {
      ...MediumImage
    }
    P1670403: file(name: { eq: "P1670403" }) {
      ...MediumImage
    }
    P1670407: file(name: { eq: "P1670407" }) {
      ...MediumImage
    }
    P1670412: file(name: { eq: "P1670412" }) {
      ...MediumImage
    }
    P1670429: file(name: { eq: "P1670429" }) {
      ...MediumImage
    }
    P1670458: file(name: { eq: "P1670458" }) {
      ...MediumImage
    }
    P1670466: file(name: { eq: "P1670466" }) {
      ...MediumImage
    }
    P1670472: file(name: { eq: "P1670472" }) {
      ...MediumImage
    }
    P1670509: file(name: { eq: "P1670509" }) {
      ...MediumImage
    }
  }
`

export default props => (
  <StaticQuery query={query} render={data => <Page data={data} {...props} />} />
)
